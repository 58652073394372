const ContactPage = () => {
  const handleSchedule = () => {
    window.open(
      "https://calendly.com/cooper-tintech/1-on-1-software-discovery-session-crafting-your-vision",
      "_blank"
    );
  };
  return (
    <div className="contact-page" id="contact">
      <h1>Contact Us</h1>
      <h3>
        Please send all inquiries to <span>cooper@tintechsolutions.com</span>
      </h3>
      <h2>OR</h2>
      <h2>Schedule a FREE 1 on 1 consultation</h2>
      <button onClick={handleSchedule}>Schedule</button>
    </div>
  );
};

export default ContactPage;
