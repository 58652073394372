import React from "react";

import Coop from "../images/coop.png";
import Mobile from "../images/mobileapp.jpg";
import Quality from "../images/quality.jpg";
import Website from "../images/website.jpg";
import Communication from "../images/communication.jpg";
import Church from "../images/church.jpeg";
import DevPipeline from "../images/devpipeline.png";
import Ellucian from "../images/Ellucian.jpeg";
import Epype from "../images/epype.jpeg";
import Intellipay from "../images/intellipay.png";
import Stg from "../images/stg.png";
import Wyssling from "../images/wyssling.png";
import Lowpricetravels from "../images/lowpricetravels.png";
import Pma from "../images/pma.jpg";
import ContactPage from "./contact";

const HomePage = () => {
  const handleSchedule = () => {
    window.open(
      "https://calendly.com/cooper-tintech/1-on-1-software-discovery-session-crafting-your-vision",
      "_blank"
    );
  };
  return (
    <div className="home-page">
      <section className="section intro-container">
        <div className="intro">
          <div>
            <small>Welcome to TinTech Solutions</small>
            <h1>
              I'm Cooper, a <br />
              <span className="job-title">Software Engineer</span>
            </h1>
          </div>
          <p>Let's build a software project</p>
          <button onClick={handleSchedule}>Schedule</button>
        </div>
        <div className="intro-img">
          <img src={Coop} alt="Full Width img" />
        </div>
      </section>
      <section className="section personal-intro">
        <p>
          Over the last four years I’ve had the pleasure of building software
          for small businesses, entreprenuers, and corporations, working side by
          side to design and develop new software and improve business
          processes. Check out some of the projects and companies I've worked
          with!
          <div className="img-list">
            <img src={Ellucian} alt="ellucian" />
            <img src={Stg} alt="stg" />
            <img src={DevPipeline} alt="devpipeline" />
            <img src={Intellipay} alt="intellipay" />
            <img src={Pma} alt="pma" />
            <img src={Wyssling} alt="wyssling" />
            <img src={Epype} alt="epype" />
            <img src={Lowpricetravels} alt="lowpricetravels" />
            <img src={Church} alt="church" />
          </div>
        </p>
      </section>
      <section className="section quote">
        <div className="container">
          <h3>Schedule a zoom call to go over your software needs below</h3>
          <button onClick={handleSchedule}>Schedule</button>
          <p>
            "Quality is never an accident; it is always the result of high
            intention, sincere effort, intelligent direction, and skillful
            execution." - William A. Foster
          </p>
        </div>
      </section>

      <section className="section services">
        <div className="container">
          <div className="service">
            <h3>Website Design</h3>
            <img src={Website} alt="Small img" />
            <p>
              Give your business the online presence it deserves with a
              professional custom website. You and I will can create a website
              tailored to your specific needs, ensuring functionality,
              user-friendliness, and an impactful design.
            </p>
          </div>
          <div className="service">
            <h3>App Development</h3>
            <img src={Mobile} alt="mobile img" />
            <p>
              Boost your business with a customized mobile or web application.
              Creating a personalized application often saves companies time and
              money, but also offers the freedom they need to run their business
              the way they want it run.
            </p>
          </div>
          <div className="service">
            <h3>Quality Assurance Testing</h3>
            <img src={Quality} alt="quality img" />
            <p>
              We ensure your software meets the highest standards with our in
              depth Quality Assurance testing. Our team takes pride in quality
              work. We thoroughly test your product to identify any issues and
              ensure a seamless user experience.
            </p>
          </div>
          <div className="service">
            <h3>Constant Communication</h3>
            <img src={Communication} alt="communication img" />
            <p>
              I maintain regular communication with my clients, fostering
              personal relationships with those I assist in their growth
              journey. This consistent interaction results in increased client
              satisfaction and higher-quality outcomes.
            </p>
          </div>
        </div>
      </section>
      <ContactPage id="contact" />
    </div>
  );
};

export default HomePage;
