import React, { useState } from "react";
import Logo from "../images/TINTECH.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img src={Logo} alt="logo"></img>
        </div>
        <div className={`navbar-links ${isOpen ? "active" : ""}`}>
          <a href="/">Home</a>
          <a href="/services">Services</a>
          <a href="/team">Our Team</a>
          <a href="#contact">Contact</a>
        </div>
        <div
          className={`navbar-toggle ${isOpen ? "active" : ""}`}
          onClick={toggleNavbar}
        >
          ☰
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
