import React from "react";
import ContactPage from "./contact";

const PricingPage = () => {
  const handleSchedule = () => {
    window.open(
      "https://calendly.com/cooper-tintech/1-on-1-software-discovery-session-crafting-your-vision",
      "_blank"
    );
  };

  return (
    <div className="pricing-page">
      <section className="section">
        <div className="container">
          <h2>My Services and Pricing</h2>
          <h3>
            Schedule a FREE consultation with me to discuss your project in more
            detail.
          </h3>

          <button onClick={handleSchedule}>Schedule a Free Consultation</button>

          <div className="pricing-card-container">
            <div className="pricing-card">
              <h3>Basic Website or Small Application</h3>
              <p>
                With my expertise, crafting business or brand websites can often
                be accomplished within just a few hours. I've learned to
                leverage available tools to deliver professional-grade websites
                at remarkably affordable rates. My proficiency extends across
                platforms such as Wix and Wordpress, in addition to developing
                custom websites.
              </p>
              <p className="price">$99 </p>
            </div>
            <div className="pricing-card">
              <h3>Standard Web or Mobile Application</h3>
              <p>
                Custom web and mobile applications are often stepping stones in
                helping businesses grow and prosper. The can save time by
                automating tasks, organizing data, and centralizing to-do items
                into an all-in-one platform. These projects can range from a few
                days to a few months, depending on the architecture of the
                project.
              </p>
              <p className="price">$50 per hour</p>
            </div>
            <div className="pricing-card">
              <h3>Quality Assurance Testing</h3>
              <p>
                Quality assurance is a big part of building software. Making
                sure content looks great on different screen sizes, button
                clicks do what they are supposed to, and ensuring correct data
                is communicated is essential. This is built into my project
                management workflow, so it is FREE. You'll be able to see the
                progress of the application as it is built.
              </p>
              <p className="price">FREE</p>
            </div>
          </div>
        </div>
      </section>
      <ContactPage id="contact" />
    </div>
  );
};

export default PricingPage;
