import React from "react";
import Coop2 from "../images/coop2.png";
import CoopAndBrynn from "../images/coopandbrynn.png";
import Resume from "../images/Cooper Tingey Resume 2024.docx.pdf";
import ContactPage from "./contact";

const TeamPage = () => {
  return (
    <div className="team-page">
      <section className="section section-gray">
        <div className="container">
          <div className="team-member">
            <div className="member-info">
              <div className="center">
                <img className="profile-img" src={Coop2} alt="John Doe" />
                <h1>Cooper Tingey</h1>
                <h2>Software Engineer</h2>
              </div>
              <p>
                I started writing software in 2020. Since then, I've earned my
                degree in Computer Science, worked for multiple companies, and
                expanded my skills and knowledge of the software industry. My
                first project was a small application that helped my little
                brother start his own business in the neighborhood. Since then,
                I've built software for various businesses such as accounting
                firms, fitness coaches, Solar Consulting firms, software payment
                platforms, and created many of my own applications. I love
                helping businesses grow and scale.
              </p>
              <p>
                I got my Bachelor's degree in Computer Science - Business after
                taking a few courses on software development and falling in love
                with it. My education as a programmer continues every single
                day. I'm proficient in the following coding languages and
                frameworks:
              </p>
              <ul>
                <li>JavaScript / TypeScript</li>
                <li>React</li>
                <li>Angular</li>
                <li>HTML / CSS</li>
                <li>C#</li>
                <li>.NET Core / ASP.Net</li>
                <li>Selenium</li>
                <li>Firebase</li>
                <li>Azure web services (cloud)</li>
                <li>Python</li>
                <li>JQuery</li>
              </ul>
              <p>Click here to learn more about my tech background: </p>
              <div className="center">
                <a href={Resume} download>
                  RESUME
                </a>
              </div>
              <p>
                Beyond coding, I enjoy playing sports and being outdoors. Golf,
                snowboarding, basketball, and running are some of my favorite
                activities. I also love hiking, fishing, exploring, and spending
                time with my wife. We got married in 2022 and have cherished
                every moment together.
              </p>
            </div>
            <div className="center">
              <img
                className="outdoor-img"
                src={CoopAndBrynn}
                alt="Outdoor Activity"
              />
            </div>
          </div>
        </div>
      </section>
      <ContactPage id="contact" />
    </div>
  );
};

export default TeamPage;
