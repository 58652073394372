import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-info">
          <p> © 2024 TinTech Solutions.</p>
          <div className="contact-info">
            <p className="email">Email. cooper@tintechsolutions.com</p>
            <p>Tel. 480-266-2083</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
